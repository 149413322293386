/* ------------------------------------------------------------------------ *\
 * Tribe Events
\* ------------------------------------------------------------------------ */

.tribe-events.tribe-common {
    .tribe-common-h1,
    .tribe-common-h2,
    .tribe-common-h3,
    .tribe-common-h4,
    .tribe-common-h5,
    .tribe-common-h6,
    .tribe-common-h7,
    .tribe-common-h8 {
        font-family: $body-font;
        text-transform: uppercase;
    }

    .tribe-common-b1,
    .tribe-common-b2,
    .tribe-common-b3,
    .tribe-common-b4,
    .tribe-common-b5,
    .tribe-common-b6 {
        font-family: $body-font;
    }

    .tribe-common--breakpoint-medium.tribe-common .tribe-common-form-control-text__input,
    .tribe-common-form-control-text__input,
    .tribe-events-c-view-selector__list-item-text,
    .tribe-common-c-btn-border-small,
    a.tribe-common-c-btn-border-small,
    .datepicker .datepicker-months td,
    .datepicker .datepicker-months th,
    .datepicker .datepicker-years td,
    .datepicker .datepicker-years th,
    .datepicker .month,
    .datepicker .year,
    .tribe-events-calendar-month__calendar-event-tooltip-datetime,
    .tribe-events-calendar-list__event-date-tag-weekday {
        font-family: $body-font;
    }

    .tribe-common-c-btn,
    a.tribe-common-c-btn,
    .datepicker .day.active,
    .datepicker .day.active.focused,
    .datepicker .day.active:focus,
    .datepicker .day.active:hover,
    .datepicker .month.active,
    .datepicker .month.active.focused,
    .datepicker .month.active:focus,
    .datepicker .month.active:hover,
    .datepicker .year.active,
    .datepicker .year.active.focused,
    .tribe-events .datepicker .year.active:focus,
    .datepicker .year.active:hover {
        background: #E3620E;
        color: #FFFFFF;
        font-family: $body-font;
    }

    .tribe-common-c-btn:hover,
    .tribe-events-c-search__button:hover {
        background: #553202;
    }

    .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-date,
    .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-date-link,
    .tribe-common-c-svgicon {
        color: #E3620E;
    }

    .tribe-events-c-ical__link {
        background: #FFFFFF;
        border: 1px solid #E3620E;
        color: #E3620E;
        font-family: $body-font;
    }

    .tribe-events-c-ical__link:hover {
        border: 1px solid #553202;
        color: #553202;
    }

    .tribe-events-c-ical__link:hover .tribe-events-c-ical__link-icon-svg path {
        stroke: #553202;
    }

    .tribe-events-calendar-month__multiday-event-bar-inner {
        background-color: rgba(115, 158, 37, 0.25);
    }
}

.single-tribe_events {
    #tribe-events-pg-template {
        max-width: 100%;
        padding: 0;
    }

    .tribe-events-schedule .recurringinfo,
    .tribe-events-schedule h2,
    .tribe-related-event-info .recurringinfo,
    .tribe-events-back a,
    .tribe-events-back a:visited,
    .tribe-events-content,
    .tribe-events-cal-links,
    .tribe-events-sub-nav {
        font-family: $body-font;
    }

    .tribe-events-schedule h2 {
        font-weight: 700;
    }

    .tribe-events-event-meta,
    .tribe-events-event-meta a,
    .tribe-events-event-meta a:visited {
        color: #E3620E;
        font-family: $body-font;
    }

    .tribe-events-event-meta a:hover {
        color: #553202;
    }

    .tribe-events-cal-links .tribe-events-gcal,
    .tribe-events-cal-links .tribe-events-ical {
        border: 1px solid #E3620E;
        color: #E3620E;
        font-family: $body-font;
        padding: 10px;
    }

    .tribe-events-cal-links .tribe-events-gcal:hover,
    .tribe-events-cal-links .tribe-events-ical:hover {
        border: 1px solid #553202;
        color: #553202;
    }

    .tribe-events-single-section {
        position: relative;
    }
}
