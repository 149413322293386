@media (max-width:1550px) {
    .container { width: 1180px; }
    .header-right { margin-top: 30px; }
    .navigation ul li a { padding: 14px 13px; font-size: 16px; }
    .translated-ltr .navigation ul li a {
        font-size: 13px;
        padding: 14px 10px;
    }
    .slideshow-content { bottom: 50px; max-width: 550px; }
    .slideshow-content a.btn { padding: 10px 20px 10px 20px; font-size: 42px; }
    .slideshow-content a.btn:after { top: 12px; }
    .slideshow-content a.btn .slideshow-arrow { width: 45px; height: 45px; margin-top: -23px; background-size: cover; }
    .welcome-section { padding: 40px 0 50px; }
    .news-image { width: 140px; }
    .news-content { padding-left: 140px; }
    .donors-block { max-width: 1000px; margin: 40px auto 50px; }
    .owl-prev { left: -80px; width: 55px; height: 55px; }
    .owl-next { right: -80px; width: 55px; height: 55px; }
    .site-footer .footer-block { padding: 0 70px; }
    .site-footer .footer-block { padding: 0; }
    ul.footer-social li { width: 35px; height: 35px; }
    ul.footer-social li a { width: 30px; height: 30px; background-size: cover; }
    ul.footer-social li.twitter a { background-position: -38px 0px; }
    ul.footer-social li.instragram a { background-position: -76px 0px; }
    ul.footer-social li.pintress a { background-position: -115px 0px; }
    .copyright { margin-top: 10px; }
    .footer-right { padding-left: 60px; }

    .btn-block a.button { font-size: 18px; line-height: 20px; }
    .translated-ltr .btn-block a.button { font-size: 16px; line-height: 18px; }
    .footer-subscreption a { max-width: 200px; }
    ul.sub-menu li a { padding: 8px 15px; }
}
@media (max-width:1200px) {
    .container { width: 992px; }
    a.button { font-size: 18px; line-height: 22px; }
    a.btn-orange,a.btn-green { padding: 13px 35px; }
    h2 { font-size: 30px; }
    h1.page-title { font-size: 30px; }
    h4 { font-size: 20px; }
    h5 { font-size: 14px; }
    .logo { padding-left: 20px; }
    .header-top .donate { padding: 10px 30px; }
    .give-now a { font-size: 16px; line-height: 16px; padding: 7px 25px; }
    .donate { font-size: 16px; line-height: 27px; padding: 7px 25px; }
    .donate.-nopadding { padding: 0; }
    .donate.-nopadding a { font-size: 16px; line-height: 27px; padding: 7px 25px; }
    .navigation ul li a { padding: 10px 8px; font-size: 14px; }
    .navigation ul li a:before { top: 0; height: 40px; }
    .translated-ltr .navigation ul li a {
        font-size: 11px;
        padding: 14px 4px;
    }
    .searchbox { float: right; padding-top: 0; margin-top: -3px; }
    .search-form { top: 40px; }
    .slideshow-content { bottom: 30px; max-width: 450px; }
    .slideshow-content a.btn { padding: 10px 20px 10px 20px; font-size: 32px; }
    .slideshow-content a.btn span { font-size: 16px; margin-top: 0; }
    .slideshow-content a.btn:after { height: 52px; right: 95px; }
    .slideshow-content a.btn .slideshow-arrow { right: 20px; }
    .welcome-section { padding: 30px 0 40px; }
    .welcome-section h2 { margin-bottom: 10px; }
    .welcome-image { margin-top: 25px; }
    .distributed-right { padding: 25px; }
    .background-opacity { padding: 30px; }
    .news-section { padding: 40px 0 35px; }
    .news-section .news-block { padding: 20px 30px 40px; }
    .donors-block { max-width: 100%; padding: 0 60px; }
    .owl-prev { left: -60px; width: 40px; height: 40px; background-size: cover; }
    .owl-next { right: -60px; width: 40px; height: 40px; background-size: cover; background-position: -52px 0px; }
    .footer-subscreption { margin-bottom: 10px; }
    .footer-subscreption a { font-size: 12px; top: 0;}
    .site-footer { padding: 20px 0 30px; font-size: 14px; }
    .sidebar { padding: 10px 20px; font-size: 14px; }
    .sidebar p { margin-bottom: 10px;  }
    .donors-section { padding: 20px 0; }
    .btn-block a.button { width: 32%; margin-left: 1%; white-space: normal; font-size: 16px; padding: 13px 5px; }
    .translated-ltr .btn-block a.button { font-size: 14px; line-height: 16px; }

    .footer-subscreption h5 { margin-bottom: 10px; display: block; float: none; }
    .footer-subscreption a { float: none; }

    .welcome-image { padding: 0; }

    .navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu { display: block; z-index: 1; position: absolute; top: 0; left: 0px; width: 170px; margin-top: 40px; background: #FFF; padding: 5px 15px 5px 15px; -webkit-box-shadow: 0px 2px 10px #909090; -ms-box-shadow: 0px 2px 10px #909090; box-shadow: 0px 2px 10px #909090; text-align: left;  }
    ul.sub-menu li a { padding: 4px 15px; }

	.page-content-block .display-posts-listing .listing-item a.title { font-size: 25px }
}

@media (max-width:991px) {
    .container { width: 740px; }
    a.button { font-size: 16px; line-height: 19px; }
    a.btn-wood { padding: 10px 25px; }
    h2 { font-size: 26px; }
    h1.page-title { font-size: 26px; }
    h4 { font-size: 18px; }
    .page-content-block h3 { font-size: 18px; }
    .page-content-block h4 { font-size: 16px; }
    .site-header { height: 85px; }
    .logo { top: 0; }
    .logo img { width: 110px; }
    .header-right { margin-top: 0; }
    .header-top,.searchbox{ display: none; }
    .header-mobile { text-align: right; position: absolute; top: 50%; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); transform: translateY(-50%); right: 30px; }
    .navigation { padding: 30px 0; position: fixed; right: -240px; top: 0; height: 100%; width: 240px; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; z-index: 19; background: #e2620d; text-align: left; overflow: auto; overflow-x: hidden; }
    .open-menu .navigation { right: 0; }
    .navigation ul li { display: block; }
    .navigation ul li a, .navigation ul li:first-child a { display: inline-block; padding: 10px; color: #FFF; }
    .navigation ul li a:hover,.navigation ul li.current-menu-item > a,.navigation ul li.current_page_ancestor > a { color: #543202; }
    .navigation .menu-main-menu-container > ul > li:first-of-type { padding-left: 10px; }
    .translated-ltr .navigation ul li a {
        font-size: 14px;

        li:first-child & {
            padding-left: 10px;
        }
    }
    .site-footer, .site-content, .site-header {  -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; left: 0; }
    .open-menu .site-footer, .open-menu .site-content, .open-menu .site-header { left: -240px; position: relative; }
    .mobile-menu { background: url(../media/mobile-bar.png); height: 32px; width: 32px; display: inline-block; margin-left: 3px;}
    .open-menu .mobile-menu { background-position: -39px 0px; }
    .mobile-menu img { opacity: 0; height: 32px; }
    .header-mobile .searchbox { display: inline-block; position: relative; float: none; margin: 0;  padding: 0; margin-left: 3px; }
    .header-mobile .searchbox img { height: 32px; }
    .search-form { top: 85px; }
    .header-buttons { display: inline-block; vertical-align: middle; }
    .header-mobile .give-now { display: inline-block; margin-top: 3px; text-align: center; }
    .header-mobile .give-now a { font-size: 14px; line-height: 18px; padding: 5px 12px; border-radius: 50px; }
    .header-mobile .give-now a .icon-heart { width: 18px; height: 18px; margin-right: 5px; background-size: cover; }

    .slideshow-section { background: none !important; }
    .slideshow-section .container { padding: 0; width: 100%; }
    .bx-wrapper .bx-viewport { -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;  }
    .slideshow-content { bottom: 0; max-width: 100%; position: relative; margin-top: 10px; }
    .slideshow-content a.btn { margin-bottom: 10px; }

    .welcome-section { padding: 10px 0 30px; }
    .welcome-section .welcome-block { padding: 0; }
    .welcome-image { text-align: center; }
    .news-section { padding: 30px 0; }
    .news-list { height: auto !important; }
    .news-image { width: 100px; }
    .news-content { padding-left: 120px; }
    .footer-right { padding-left: 20px; padding-top: 20px; }
    .footer-left:after { content: none; }
    .btn-block { text-align: center; margin-top: 30px; }
    .btn-block a.button,.btn-block a.button:first-child { width: 100%; margin-left: 0; max-width: 295px; display: block; margin: 10px auto 0; }

    .site-content { margin-bottom: 0 !important; }
    .secondary-content .col-md-4 { text-align: center; }

    .navigation ul li.open > ul.sub-menu,.navigation ul li:hover > ul.sub-menu { position: static; margin-top: 0; width: 100%; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; background: transparent; padding: 0 0 0 15px; }
    .navigation ul li a { position: relative; }
    .navigation ul li a:before,.navigation ul li ul.sub-menu li:first-child a:before,.navigation ul li ul.sub-menu li a:before {  content: "";position: absolute;bottom: 0;left: -30px;right: 0;background: #FFF;height: 1px;width: 500%; }

    .navigation ul li.menu-item-has-children span.plus { background-image: url(../media/mobile-submenu.png); background-repeat: no-repeat; position: absolute; right: 20px; top: 9px; width: 20px; height: 20px; z-index: 1; display: block; }

    .page-content-block div[class*="col-"]:not(:last-of-type) ul, .inner-content div[class*="col-"]:not(:last-of-type) ol { margin-bottom: 0; }

	.page-content-block .display-posts-listing .listing-item a.title { font-size: 22px }

    .page-content-block--reverse {
        & {
            display: flex;
            flex-direction: column-reverse;
        }

        > * {
            width: 100%;
        }

        .sidebar {
            margin-bottom: 20px;
        }
    }
}

@media (max-width:767px) {
    .container { width: 100%; }
    a.button { padding: 10px 80px; margin-top: 10px; }
    a.btn-wood { padding: 10px 35px; }
    body { font-size: 13px; line-height: 17px; }
    .logo { margin-left: -8px; top: 3px; }
    .header-mobile { right: 15px; }
    .header-mobile .give-now { display: block; }
    .search-form { width: 180px; }
    .slideshow-content a.btn { font-size: 27px; letter-spacing: 1px; }
    .slideshow-content a.btn span { font-size: 10px; letter-spacing: 0px; }
    .slideshow-content a.btn .slideshow-arrow { width: 32px; height: 32px; margin-top: -16px; }
    .slideshow-content a.btn:after { height: 36px; right: 65px; top: 10px; }
    .welcome-section { padding: 15px 0 30px; text-align: center; }
    .welcome-section h2 { margin-bottom: 5px; }
    .welcome-image { margin: 35px 0 25px; }
    .welcome-image img { width: 213px; }
    .distributed-right { padding: 30px 0; text-align: center; }
    .background-opacity { padding: 25px; }
    .distributed-hours ul { margin: 25px auto 0; max-width: 320px; }
    .distributed-hours ul li { margin-bottom: 7px; text-align: left; }
    .news-section { overflow: hidden; }
    .news-list { margin-bottom: 15px; float: left; width: 100%; display: table; }
    .news-section .news-block {padding: 15px 5px 40px; position: relative; border-bottom: 0px none;}
    .news-section .news-block:after { content: ""; position: absolute; bottom: 0; left: -10%; right: -10%; width: 1000%; background: rgba(57,33,1,0.22); height: 1px; }
    .news-section a.button { padding: 10px 45px; }
    .news-image { width: 60px; display: table-cell; }
    .news-content { padding-left: 20px; padding-top: 0; display: table-cell; vertical-align: middle; width: 100%; }
    .news-content p { display: none; }
    .news-block .text-center { text-align: center; margin-top: 10px; }
    .donors-section h2 { padding: 0 20px; line-height: 23px; }
    .donors-block { margin: 20px auto 0; }
    .owl-prev { width: 25px; height: 25px; margin-top: -12px; }
    .owl-next { width: 25px; height: 25px; margin-top: -12px; background-position: -33px 0px }

    .site-footer { padding: 25px 0 60px; font-size: 12px; text-align: center; }
    .site-footer h4 { margin-bottom: 5px; }
    .site-footer h5 { font-size: 12px; line-height: 14px; margin-bottom: 0;  }
    .address-mailing { margin-bottom: 20px; }
    ul.footer-social { margin: 30px 0 0; }
    .footer-left { position: static; }
    .desktop-copyright { display: none; }
    .mobile-copyright { display: block; }
    .site-footer .footer-left h5 span { display: inline-block; }
    .site-footer h5 span { display: block; margin-top: 5px; }
    .footer-right h5 { margin-bottom: 20px; margin-top: 10px; float: none; }
    .footer-subscreption a { font-size: 12px; line-height: 14px; padding: 7px; float: none; max-width: 180px; }
    .footer-right h4 { margin-bottom: -5px; }
    .sitemap h4 { margin-bottom: 4px; }
    .footer-subscreption { padding: 0 20px; text-align: center; }

    .d-block {
        display: block;
    }

    .d-none {
        display: none;
    }

    .banner-single { background: none!important; }
    .banner-single .container { padding: 0; }
    .inner-page-block { padding: 0; }
    .page-content-section { padding: 10px 0; }
    .page-content-section .container { padding-left: 15px; padding-right: 15px; }
    .inner-page-block p { margin-bottom: 8px; }
    .btn-block { margin-top: 25px; }
    .btn-block a.button { font-size: 16px; padding: 10px 5px; }
    .page-content-block { padding-bottom: 35px; }
    .btn-block:after { bottom: -32px; }
    .secondary-content { padding: 0px 0 40px; margin: 0 -15px; }
    .secondary-content [class^='col'] { padding-left: 15px; padding-right: 15px; }
    .secondary-content .col-md-4 { padding: 0; }
    .secondary-content h2.page-title { margin-bottom: 3px; }
    .critical-need { font-size: 13px;line-height: 20px; margin: 25px 0; }
    .perishable-food ul { margin: 10px 0; }
    .perishable-food ul li { width: 100%; padding-bottom: 3px; }
    .secondary-content a.btn-green { font-size: 16px; padding: 10px 5px;  width: 100%;  max-width: 295px; }
    .sidebar { margin-top: 25px; text-align: center; padding: 20px 10px; font-size: 13px; }
    .sidebar p,.sidebar h4 { margin-bottom: 15px; }
    .sidebar table { display: inline-block; }
    .sidebar ul, .sidebar ol { list-style: none; }

    .secondary-content blockquote { padding: 20px 0;margin: 0;font-size: 14px;border-left: 0px;   line-height: 20px; }

	.alignleft, .alignright { display: block; float: none; margin: 20px auto; }

}
