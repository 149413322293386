$body-font: "Helvetica", "Arial", sans-serif;

*,
*:before,
*:after { // Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
	box-sizing: inherit;
}
html {font-family: $body-font;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%; box-sizing: border-box; position: relative; min-height: 100%; }
body {	margin: 0;  }
ul,ol { margin: 0 0 20px; padding: 0 0 0 20px; }
ul { list-style: disc; }
ol { list-style: decimal; }
li > ul, li > ol { 	margin-bottom: 0; margin-left: 1.5em; }
img { height: auto; max-width: 100%; }
a { color: #000; }
a:hover,a:focus,a:active { text-decoration: none; outline: 0px none;}
a:hover,a:active { outline: 0; }
a:hover { color: #19558f; }
.alignleft { display: inline; float: left; margin-right: 1.5em; }
.alignright { display: inline; float: right; margin-left: 1.5em; }
.aligncenter { clear: both; display: block; margin-left: auto; margin-right: auto; }
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

/*---------------------- THEME CSS ---------------------------*/

body { font-size: 16px; line-height: 20px; color: #372001; font-family: $body-font; }
h1,h2,h3,h4,h5,h6, { font-family: $body-font; text-transform: uppercase; }
h1 { font-size: 40px; word-spacing: 2px; }
h2 { font-size: 34px; word-spacing: 2px; }
h3 { font-size: 28px; word-spacing: 1px; }
h4 { font-size: 22px; }
h5 { font-size: 15px; }

article { padding: 20px 0; }

a{ color: #3C954E; }
a.button,  input[type="button"].button { font-size: 21px; line-height: 24px; border: 0px; border-radius: 0px;  font-family: $body-font; text-transform: uppercase; word-spacing: 1px; margin-top: 15px; white-space: normal; }
a.btn-orange { background: #e2620d; color: #FFF; padding: 13px 65px; }
a.btn-wood { background: #5f3802; color: #FFF; padding: 12px 45px; }
a.btn-green, input[type="button"].btn-green { background: #739e25; color: #FFF; padding: 13px 65px; }
a.btn-orange:hover { background: #5f3802; color: #FFF; }
a.btn-green:hover,a.btn-wood:hover { background: #e2620d; color: #FFF; }
.no-padding { padding: 0; }

input::-moz-placeholder{ font-size: 16px; font-weight: 400;  text-transform: uppercase; color: #5f3802; }
input::-webkit-input-placeholder{ font-size: 16px; font-weight: 400;  text-transform: uppercase; color: #5f3802; }
input::-ms-input-placeholder { font-size: 16px; font-weight: 400;  text-transform: uppercase; color: #5f3802; }

.hide-hr { border-bottom: none !important; border-top: none !important; }

/*---------------------- HEADER CSS -------------------*/
.site-header { position: relative; }
.header-top { text-align: right; }
.header-top .give-now,.header-top .donate { display: inline-block; vertical-align: top; }
.header-top .donate { font-weight: 700; color: #ffffff; background: #5a8015; padding: 17px 40px; line-height: 21px; text-transform: uppercase; }
.header-top .donate.-nopadding { padding: 0; }
.header-top .donate a { font-weight: 700; color: #ffffff; display: inline-block; background: #5a8015; padding: 17px 40px; line-height: 21px; text-transform: uppercase; }
.give-now a { background: #739e25; color: #FFF; display: inline-block; padding: 14px 35px; margin-left: 10px; text-transform: uppercase; font-family: $body-font; font-size: 18px; word-spacing: 3px; }
.give-now a.green { background: #739E25; }
.give-now a.orange { background: #E2620D; }
.give-now a.wood { background: #543202; }
.give-now a .icon-heart { width: 27px; height: 27px; display: inline-block; margin-right: 20px; vertical-align: middle; background: url(../media/icon-heart.png); position: relative; top: -2px; }
.logo { position: absolute; top: 10px; padding-left: 55px; z-index: 1;}
.logo a { display: inline-block; }
.header-right { margin-top: 48px; float: right; text-align: right; }
.searchbox { float: right; padding-top: 3px; }
.search-form {position: absolute; right: 20px; top: 48px; z-index: 1; max-width: 260px; padding: 15px 20px 0; background: #FFF; display: none; }
.search-form input { background: #dbb075; font-size: 14px; color: #5f3802; max-width: 220px; border: 0px; box-shadow: none; width: 100%; margin-bottom: 15px; font-family: $body-font; font-size: 16px; font-weight: 400;  text-transform: uppercase; color: #5f3802; padding: 8px; display: inline-block; text-align: center; }
.search-form input[type="submit"] { background: #739e25; color: #FFF;  }
.navigation ul { padding: 0; margin: 0;  }
.navigation ul li { display: inline-block; position: relative; }
.navigation ul li a { display: inline-block; padding: 14px 29px; color: #553202; font-family: $body-font; text-transform: uppercase; font-size: 18px; position: relative; }
.ie10 .navigation ul li a,.ie11 .navigation ul li a { font-size: 16px; }
.navigation ul li a:hover,.navigation ul li.current-menu-item > a,.navigation ul li.current_page_ancestor > a { color: #e2620d; }
.navigation .menu-main-menu-container > ul > li > a:before { content: ""; position: absolute; top: -6px; left: 0; height: 54px; width: 1px; background: rgba(95,56,2,0.25); margin-left: -3px;}
.navigation .menu-main-menu-container > ul > li:first-child > a { padding-left: 0; }
.navigation .menu > li > a {
    font-weight: 700;
}

.navigation .menu .sub-menu > li > a {
    text-transform: none;
}
.navigation ul li:first-child a:before { content: none; }

.sub-menu { display: none; }
.navigation ul li:hover > ul.sub-menu { display: block; z-index: 1; position: absolute; top: 0; left: 0px; width: 200px; margin-top: 45px; background: #FFF; padding: 5px 15px 5px 15px; -webkit-box-shadow: 0px 2px 10px #909090; -ms-box-shadow: 0px 2px 10px #909090; box-shadow: 0px 2px 10px #909090; text-align: left; }
.navigation ul ul li:hover > ul.sub-menu { left: 100%;  margin-top: 0;  }
ul.sub-menu li { display: block; }
ul.sub-menu li a { padding: 8px 10px; }
.navigation ul li.menu-item-has-children span.plus { display: none; }

.translated-ltr .navigation ul li a {
    font-size: 16px;
    padding: 14px 18px;
}

/*------------------------ FOOTER CSS -----------------*/
.site-footer { padding: 35px 0 70px; color: #FFF; font-size: 15px; background-image: linear-gradient(to bottom, #372001 0%, #543202 100%); bottom: 0; left: 0; right: 0;
}
.site-footer .footer-block { padding: 0 100px; float: left; width: 100%; }
.site-footer h4 { color: #dbb075; margin-bottom: 7px; }
.site-footer h5 { margin-top: 0; font-family: $body-font; font-weight: 700; text-transform: none; margin-bottom: 5px; }
.site-footer h5 a,.site-footer h5 span { font-weight: 400; color: #FFF; }
.address-pantry { margin-bottom: 23px; }
.mobile-copyright { display: none; }
.copyright { margin-top: 10px; color: #dbb075; }
.weblinx { margin-top: 10px; color: #dbb075; }
.weblinx a { color: #dbb075; }
ul.footer-social { margin: 25px 0 0; padding: 0; list-style: none; }
ul.footer-social li { display: inline-block; width: 40px; height: 35px; }
ul.footer-social li a { background: url(../media/footer-social.png); background-repeat: no-repeat; width: 35px; height: 35px; display: block; }
ul.footer-social li img { opacity: 0; }
ul.footer-social li.facebook a{ background-position: 0px 0px; }
ul.footer-social li.twitter a{ background-position: -44px 0px; }
ul.footer-social li.instragram a{ background-position: -89px 0px; }
ul.footer-social li.pintress a{ background-position: -135px 0px; }

.footer-left { position: relative; }
.footer-left:after { content: ""; position: absolute; top: 5px; width: 1px; height: 200px; background: rgba(255,255,255,0.23); right: -15px; }
.footer-right { padding-left: 60px; }
.footer-right h5 { margin-bottom: 25px; }


.footer-subscreption { float: left; width: 100%}
.footer-subscreption a { background: #E2620D; color: #FFFFFF; max-width: 220px; width: 100%; margin-bottom: 15px; text-transform: uppercase; padding: 8px; display: inline-block; text-align: center; float: right; position: relative; top: -10px; }
.footer-subscreption h5 { display: inline-block; padding-right: 10px; float: left; }
.footer-subscreption h5 span { padding-left: 8px; }
.footer-subscreption:last-child a { background: #739e25; color: #FFF; }
.sitemap ul { padding: 0; margin: 0 0 0 -5px; list-style: none; }
.sitemap ul li { display: inline-block; }
.sitemap ul li a { color: #FFF; padding: 5px; position: relative; }
.sitemap ul li a:after { content: "-"; position: absolute; top: 50%; right: -5px; color: #FFF; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); transform: translateY(-50%); }
.sitemap ul li:last-child a:after { content: none; }

/*------------------------ BANNER CSS --------------------------------*/
.slideshow-section { background-position: center; background-repeat: no-repeat; background-size: cover; position: relative; overflow: hidden; }
.slideshow-section .container { width: 100%; padding: 0 0 32px; }
.bx-wrapper .bx-viewport { border: 0px none; left: 0; -webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); -moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); }
ul.bxslider { margin: 0; padding: 0; list-style: none; }
ul.bxslider li { background-position: center; background-repeat: no-repeat; background-size: cover; }
ul.bxslider li img { height: 100%; object-fit: cover; opacity: 0; width: 100%; }
.slideshow-content { position: absolute; bottom: 90px; max-width: 587px; width: 100%; }
.slideshow-content a.btn { display: block; margin-bottom: 13px; padding: 18px 35px 18px 35px; color: #FFF; text-align: left; border-radius: 0; font-size: 50px; line-height: 1.2em; font-family: $body-font; text-transform: uppercase; position: relative; }
.slideshow-content a.btn:after { content: ""; position: absolute; top: 18px; height: 65px; width: 1px; background: #b14700; right: 120px; }
.slideshow-content a.btn.donate{ background: #e2620d;  }
.slideshow-content a.btn:after { background-color: rgba(0,0,0,0.2); }
.slideshow-content a.btn span { font-size: 18px; display: block; font-family: $body-font; text-transform: none; line-height: 1.2em; }

.translated-ltr .slideshow-content a.btn {
    font-size: 35px;

    & span {
        font-size: 18px;
    }
}

.slideshow-content a.btn .slideshow-arrow { position: absolute; top: 50%; margin-top: -27px; background: url(../media/slideshow-arrow.png); width: 53px; height: 53px; right: 35px; background-repeat: no-repeat; }


.slideshow-content.cta_links {
    display: flex;
    position: static;
    width: 100%;
    max-width: 100%;

    a.btn::after {
        display: none;
    }

    a.btn {
        flex: 1 1 auto;
        margin-bottom: 0;
    }
}

.bxslider li .slide_content {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    left: 50%;
    transform: translateX(-50%);

    .slide_title {
        color: #FFFFFF;
        font-family: $body-font;
        font-size: 1.5em;
        font-weight: 700;
    }

    p, li, a {
        color: #FFFFFF;
        font-family: $body-font;
        font-size: 18px;
    }

    .btn {
        background: #739E25;
        padding: 10px 35px;
        display: inline-block;
        margin-bottom: 5px;
        font-family: $body-font;
        font-size: 18px;
    }

    .btn:hover {
        background: #5A8015;
    }
}

.owl-carousel .owl-wrapper {
    display: flex !important;
    align-items: center;
}

@media (max-width: 1199px) {
    .slideshow-content.cta_links {
        flex-wrap: wrap;

        a.btn {
            flex: 0 0 100%;
            margin-bottom: 13px;
        }
    }

    ul.bxslider li {
        background-image: none !important;
    }

    ul.bxslider li img {
        height: auto;
        opacity: 1;
    }

    .bxslider li .slide_content {
        bottom: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
        padding: 0;
        position: relative;
    }
}


/*--------------------------- WELCOME CSS ---------------------*/
.welcome-section { padding: 50px 0 60px; }
.welcome-section .welcome-block { padding: 0 60px; }
.welcome-section h2 { color: #e2620d; margin-bottom: 7px; }

/*----------------- DISTRIBUTED CSS ---------------------*/
.distributed-image,.distribute-section { background-size: cover; background-repeat: no-repeat; background-position: center; height: 100%; }
.distributed-image { -webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); -moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); }
.distribute-section { overflow: hidden; }
.distributed-image img { opacity: 0; }
.distributed-right { padding: 35px 35px 35px 45px; }
.distributed-hours { margin-bottom: 20px; }
.background-opacity { background: rgba(255,255,255,0.8); padding: 35px 40px; }
.background-opacity h2 {margin-top: 0; color: #553202; margin-bottom: 5px; }
.distributed-hours ul { list-style: none; padding: 0; margin: 25px 0 0; }
.distributed-hours ul li { display: flex; margin-bottom: 7px; }
.distributed-hours ul li span { float: left; font-weight: 700; text-transform: capitalize; width: 40%; }
.visiting { padding: 30px 40px; }

/*---------------------------- NEWS SECTION ----------------*/
.news-section { padding: 55px 0 ; }
.news-section .news-block { padding: 30px 50px 60px; border-bottom: 1px solid rgba(57,33,1,0.22);  }
.news-section h2 { text-align: center; color: #739e25; margin-top: 0; }
.news-list { margin-bottom: 38px; }
.news-image { float: left; width: 143px; padding-right: 15px; }
.news-image a, .news-image img { width: 100%; height: auto; }
.news-title a { color: #e2620d; font-weight: 700; margin-bottom: 6px; display: inline-block; }

/*---------------------------- DONORS CSS -----------------*/
.donors-section h2 { color: #5f3802; text-align: center; margin-top: 0;}
.donors-block { max-width: 1110px; margin: 50px auto 70px; }
.owl-prev { background: url(../media/donor-arrow.png); background-repeat: no-repeat; width: 65px; height: 55px; background-position: 0px 0px; font-size: 0; left: -100px; position: absolute; top: 50%; margin-top: -26px;  }
.owl-next { background: url(../media/donor-arrow.png); background-repeat: no-repeat; width: 65px; height: 55px; background-position: -73px 0px; font-size: 0; right: -100px; position: absolute; top: 50%; margin-top: -26px;  }
.owl-carousel  .owl-wrapper, .owl-carousel  .owl-item { text-align: center; }

/*---------------------- INNER CSS ----------------------*/
.banner-single { overflow: hidden; background-position: center; }
.page-banner { -webkit-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); -moz-box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.54); }
.page-banner img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.page-content-section { padding: 20px 0; }
.inner-page-block { padding: 0 56px ; }
.inner-page-block p { margin-bottom: 20px; }
.page-content-block { padding-bottom: 42px; margin-bottom: 25px; border-bottom: 2px solid #dcd8d2; }
.page-content-block.hide-hr { border-bottom: none; }
.page-content-block.-condensed { border-bottom: none; margin-bottom: 0; padding-bottom: 0; }
.page-content-block h1, .page-content-block h2, .page-content-block h3, .page-content-block h4, .page-content-block h5, .page-content-block h6 { margin-top: 0; }
h1.page-title { font-size: 34px; margin-top: 20px; }
.page-title { color: #e2620d; margin-bottom: 5px; }
h1.page-title.-blog { margin-bottom: 15px; }
.page-content-block h2 {
    color: #739e25;
}
.page-content-block h2 strong, .page-content-block h3 strong, .page-content-block h4 strong, .page-content-block h5 strong { color: #ff0000; }
.page-content-block h3 { font-size: 22px; }
.page-content-block h4 { font-size: 18px; }
.page-content-block h3, .page-content-block h4 { text-transform: none; font-weight: 700; }
.page-content-block table td, .page-content-block table th { padding-left: 5px; padding-right: 5px; }
.page-content-block hr { border-top: 1px solid #dcd8d2; }
.subtitle { color: #000; font-weight: 700; margin-bottom: 20px;}
.btn-block { position: relative; }
.btn-block:after { content: ""; position: absolute; bottom: -42px; left: 0; right: 0; background: rgba(57,33,1,0.22); width: 100%; height: 1px; }
.btn-block a.button { display: inline-block; width: 31%; margin-left: 3%; padding: 13px; text-align: center; }
.btn-block a.button:first-child { margin-left: 0; }
.page-content-block .button { transition: background-color .15s; }
.page-content-block .button:hover { background-color: lighten(#e3620e, 10%); }
.page-content-block .button.-secondary { background-color: #543202; }
.page-content-block .button.-secondary:hover { background-color: lighten(#543202, 10%); }
.page-content-block .button.-tertiary { background-color: #729e25; }
.page-content-block .button.-tertiary:hover { background-color: lighten(#729e25, 10%); }
.secondary-content h2.page-title { color: #739e25; margin-bottom: 8px; }
.critical-need { font-size: 16px; line-height: 26px; margin: 45px 0; }
.critical-need i { font-weight: 700; color: #e00000; }
.perishable-food ul { margin: 18px 0 30px; padding: 0; list-style: none; float: left; width: 100%; }
.perishable-food ul li { float: left; width: 50%; padding-right: 20px; padding-bottom: 8px; }
.secondary-content a.btn-green{ padding: 5px 35px; font-size: 16px; }
.sidebar { background: #dbb075; padding: 20px 40px 30px; }
.sidebar h4 { font-size: 22px; margin-bottom: 20px; text-transform: uppercase; }
.sidebar a { color: #372001; }
.sidebar a:hover { color: #e2620d; }
.donors-section { padding: 40px 0; }
.secondary-content blockquote { padding: 40px 0; margin: 0; font-size: 16px; border-left: 0px; line-height: 24px; }

.search-form.results { border: 1px solid #e2620d; display: block !important; max-width: 100%; position: static; width: 100%; }
.search-form.results input:not([type="submit"]) { background: none; border: 1px solid #dbb075; max-width: 100%; text-align: left; }

.page-content-block .button {
    background-color: #e3620e;
    color: #ffffff;
    display: inline-block;
    margin: 0;
    padding: 10px 20px;
    transition: background-color .15s;
}

.page-content-block .button:hover {
    background-color: lighten(#e3620e, 10%);
}

.page-content-block .button.-alt {
    color: #5a8015;
}

.page-content-block .button.-alt:hover {
    background-color: lighten(#5a8015, 10%);
}

/*--------------- DISPLAY POST LISTING -----------------*/
.page-content-block .display-posts-listing { display: flex; flex-direction: column; margin-top: 10px; }
.page-content-block .display-posts-listing .listing-item { margin-bottom: 30px }
.page-content-block .display-posts-listing .listing-item strong { font-weight: normal; }
.page-content-block .display-posts-listing .listing-item a.image { float: left; margin-right: 20px; }
.page-content-block .display-posts-listing .listing-item a.title { font-family: $body-font; display: block; font-size: 28px; line-height: 28px; margin-bottom: 5px; text-transform: uppercase; }
.page-content-block .display-posts-listing .listing-item .date { font-weight: bold; }
.display-posts-listing .listing-item .title + .excerpt-dash { display: none; }
.listing-item a.image, .listing-item img { width: 100%; padding-right: 15px; }
.listing-item a.image { max-width: 150px; }
html #wpadminbar {
    position: fixed !important;
}

.donate.translate_widget .menu-item {
    list-style: none;
    margin-left: -4px;
}

@media (max-width: 991px) {
    .donate.translate_widget .menu-item {
        display: inline-block;
        margin-left: 0;
        margin-top: 10px;

        a {
            padding: 4px 11px;
            color: #FFFFFF;
            background: #739e25;
            border-radius: 50px;
        }
    }
}

.btn {
    white-space: normal;
}

.header_btn_wrapper {
    width: 90px;
    float: left;
}

.d-md-none {
    display: none !important;
}

.d-md-block {
    display: block !important;
}

/*--------------- TINGLE -----------------*/

.tingle-modal {
    & {
        z-index: 100002;
    }
}

.tingle-modal-box {
    & {
        max-width: remify(800, 16);
    }
}

.tingle-modal-box__content {
    .tingle-modal--image & {
        padding: 0;
    }
}

.tingle__image {
    & {
        display: block;
        width: 100%;
    }
}

.tingle__page-content-block {
    border: 0;
    margin: 0;
    padding: 0;
}

.pagination.navigation,
.posts-navigation.navigation {
    display: block;
    background: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    right: auto;
    text-align: center;
    width: auto;
}

.page-template-tp-blank {
    .page-content-block {
        border-bottom: none !important;
    }
}
